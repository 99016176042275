import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SubBanner from '../components/SubBanner'
import ContactBanner from '../components/ContactBanner'
import SEO from '../components/SEO'
import SubpageContent from '../components/Subpage/SubpageContent'


export default function About({ data }) {
  const { title, subtitle: { subtitle }, bannerImage } = data.contentfulSubpagesBanner
  const { subpageContentSection } = data.contentfulSubpageContent
   
  return (
    <Layout>
      <SEO title={title} description={subtitle} />
      <SubBanner title={title} subtitle={subtitle} image={bannerImage} />
      <SubpageContent content={subpageContentSection} booking="To schedule a site visit," />
      <ContactBanner />
    </Layout>
  )
}

export const query = graphql`
query About {
    contentfulSubpagesBanner(contentful_id: { eq: "4cMt4knIMUh9RhHev9K2x4"}) {
      title
      subtitle {
          subtitle
      }
      bannerImage {
        fluid {
            ...GatsbyContentfulFluid
        }
      }
    }
    
      contentfulSubpageContent(contentful_id: {eq: "n98R2hTPlkF8txyjPsL86"}) {
        subpageContentSection {
          title
          subpageImage {
            file {
              url
            }
          }
          childContentfulSubpageContentBlockSubpageContentTextNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    
  }
`





// contentfulSubpageContentBlock(contentful_id: { eq: "D50K1tDKTO8jFUPSTbFed"}) {
//   subpageContent {
//     childMarkdownRemark {
//       html
//     }
//   }
//   subpageImage {
//     file {
//       url
//     }
//   }
// }